.home {
  padding: 1rem;
  display: flex;
  gap: .5rem;

  &>div {
    flex-grow: 1;

    a {
      display: block;
      padding: 5
    }

    &>.home-body {
      padding: .5rem;
      background-color: #f7f7f7;
      border: solid 1px #e7e7e7;
    }

    &:first-child {
      margin-top: 38px;

      &>.home-body {
        padding: .5rem;
        padding-top: 40px;
        background-color: transparent;
        border: solid 1px #e7e7e7;
      }
    }
  }
}

.home-header {
  background-color: #464775;
  border: solid 1px #2f3057;
  padding: .5rem;
  &>h4 {
    margin: 0;
    font-weight: 500;
    color: #fff;
  }
  &>input {
    margin: .5rem;
  }
}