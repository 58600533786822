.main {
  display  : flex;
  flex     : auto;
  flex-grow: 1;

  &>div {
    max-width       : 250px;
    background-color: #f7f7f7;
    border-right    : solid 1px #e7e7e7;

    &>aside {
      &>ul {
        list-style: none;
        margin    : 0;
        padding   : 0.5rem 0;

        &>li {
          min-height   : 40.5px;
          min-width    : 220px;
          display      : flex;
          align-items  : center;
          padding-right: .5rem;

          &:hover {
            background-color: #e7e7e7;
            cursor          : pointer;
          }

          &>a {
            text-decoration: none;
            color          : inherit;
            display        : block;
            width          : 100%;
            padding        : .65rem .85rem;

            &>span {
              &:last-child {
                padding-left: .5rem;
              }
            }
          }

          &>div {
            padding     : .5rem;
            position    : relative;
            margin-right: -.5rem;
            z-index     : 100;
          }
        }

        &>.sub {
          padding-left: 2rem;
        }
      }
    }
  }

  &>main {
    flex-grow: 1;
  }
}

.icon-only-nav {
  &>aside {
    &>ul {
      &>li {
        padding-right: 0 !important;
        min-width    : 0 !important;

        &>a {
          padding: 0.65rem 0.82rem !important;

          &>span {
            &:last-child {
              display: none;
            }
          }
        }

        &>div {
          display: none;
        }

        &>ul {
          display         : none;
          position        : absolute;
          left            : 43px;
          top             : 0;
          z-index         : 100000;
          min-width       : 250px;
          background-color: #f7f7f7;
          border          : solid 1px #e7e7e7;
          margin          : 0;
          padding         : 0;
          list-style      : none;
          box-shadow      : 4px 4px 8px 2px #00000066;

          &>li {
            min-height   : 40.5px;
            min-width    : 220px;
            display      : flex;
            align-items  : center;
            padding-right: 0.5rem;

            &:hover {
              background-color: #e7e7e7;
            }

            &>a {
              text-decoration: none;
              color          : inherit;
              display        : block;
              width          : 100%;
              padding        : 0.65rem 0.85rem;
            }
          }
        }
      }
    }
  }
}

.with-submenu {
  position: relative;

  &:hover {
    &>ul {
      display: block;

      &:last-of-type {
        position: absolute;
        display : block;
        right   : 10px;
      }
    }
  }
}