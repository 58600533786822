.x0 {
  padding: 0.5rem;

  & > div {
    & > div {
      & > div {
        background-color: #f7f7f7;
        border: solid 1px #e7e7e7;
      }

      &:first-child {
        display: flex;
        gap: 0.5rem;

        & > div {
          height: 250px;
          width: 250px;
          &>h3 {
            margin: 0;
            text-transform: uppercase;
            text-align: center;
            padding: .5rem;
            background-color: #e7e7e7;
            font-weight: 400;
          }

          &>div {
            padding: .5rem;
          }
        }
      }

      &:last-child {
        margin-top: .5rem;
      }
    }
  }
}
