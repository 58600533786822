.header {
  display: flex;
  background-color: #f7f7f7;
  border-bottom: solid 1px #e7e7e7;

  &>div {
    &:nth-child(2) {
      flex-grow: 1;
    }

    &>ul {
      border-top: solid 0 transparent;
      border-left: solid 0 transparent;
      &>li {
        border-radius: 0 !important;
      }
    }
  }

  &>.search {
    padding: .3rem .5rem;
  }
}

.sidebar-button {
  &>button {
    height: 45px;
    width: 45px;
    border-radius: 0;
    background-color: #f7f7f7;
    border: solid 0px transparent;
    border-right: solid 1px #e7e7e7;
    cursor: pointer;
  }
}

nav {
  display: grid;
  height: 100%;
  align-items: center;

  .ui-menu__itemindicator {
    display: none !important;
  }

  .ui-menu__item__submenu {
    &>li {
      a {
        text-decoration: none !important;
        border: none;
        width: 100%;
        display: flex;
        align-items: center;
        gap: .5rem;

        &>* {
          display: block;
        }

        &>span {
          flex-grow: 1;
        }
      }
    }
  }
}

  