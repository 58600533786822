.x1 {
  padding: .5rem;

  &>div {
    &>h4 {
      padding: .5rem;
    }
  }
}

