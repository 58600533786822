.prozesses {
  padding: .5rem;
  background-color: #f7f7f7;
}

.bp-header-title {
  &>h1 {
    display: flex;
    font-size: 1.2rem;
    &>label, &>div {
      display: block;
      padding: .5rem;
      font-weight: 400;
    }
  }
}

.bp-header-info {
  display: flex;

  &>div {
    flex-grow: 1;
    &>div {
      display: flex;
      &>label, &>div {
        display: block;
        padding: .5rem;
      }
    }
    
  }
}

.bp-body {
  margin-top: 2rem;
}

.bp-content {
  background-color: #fff;
  border-top: solid 1px #e7e7e7;
  border-bottom: solid 1px #e7e7e7;
  margin: 0 -.5rem;
}

.bp-body-info {
  display: flex;

  &>div {
    flex-grow: 1;
    &>div {
      padding: .5rem;
      &>label, &>div {
        display: block;
        padding: .5rem;
      }
      &>label {
        padding-bottom: 0;
      }
      &>div {
        padding-top: 0;
      }
    }
    
  }
}

.bp-items-info {
  margin-top: 2rem;
  padding: 1rem;

  &>h1 {
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.tab-menu {
  border-bottom: solid 0.125rem rgb(225, 223, 221);

  &>button {
    padding: 0.25rem;
    border: solid 0 transparent;
    color: rgb(98, 100, 167);
    line-height: 1.4286;
    background-color: transparent;
    margin-right: 0.5rem;
    border-radius: 0;

    &:hover {
      color: #000;
      cursor: pointer;
      &>span {
        border-bottom: solid 0.25rem #e7e7e7;
      }
    }
  }

  &>.active {
    &>span {
      border-bottom: solid 0.25rem rgb(98, 100, 167);
    }
  }
}

.options-bar {
  padding: .5rem;
  &>button {
    padding: 0.25rem;
    border: solid 0 transparent;
    color: rgb(98, 100, 167);
    line-height: 1.4286;
    background-color: transparent;
    margin-right: 0.5rem;
    border-radius: 0;

    &:hover {
      color: #000;
      cursor: pointer;
      &>span {
        border-bottom: solid 0.25rem #e7e7e7;
      }
    }
  }
}
