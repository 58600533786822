.project {
  padding: 2rem;

  &>div {
    &>h4 {
      padding: .5rem;
    }
  }
}

.project-progressbar {

}

.progress {
  display: flex;
  padding: 0;
  list-style-type: none;
  font-family: arial;
  font-size: 12px;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
  text-align: center;

  &>div {
    flex-grow: 1;
    background-color: #e7e7e7;
  }
}

.progress li {
  padding: 10px 30px 10px 40px;
  background: #aaa;
  color: #fff;
  position: relative;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  width: 32%;
  margin: 0 1px;
}

.progress li:before {
  content: '';
  border-left: 16px solid #fff;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  
}
.progress li:after {
  content: '';
  border-left: 16px solid #aaa;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

.progress li.active {
  background: #555;
}

.progress li.active:after {
  border-left-color: #555;
}