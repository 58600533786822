.kumat {
  padding: .5rem;
  background-color: #f7f7f7;
}

.kumat-header-title {
  &>h1 {
    display: flex;
    font-size: 1.2rem;
    &>label, &>div {
      display: block;
      padding: .5rem;
      font-weight: 400;
    }
  }
}

.kumat-header-info {
  display: flex;

  &>div {
    flex-grow: 1;
    &>div {
      display: flex;
      &>label, &>div {
        display: block;
        padding: .5rem;
      }
    }
    
  }
}

.kumat-body {
  margin-top: 2rem;
}

.kumat-content {
  background-color: #fff;
  border-top: solid 1px #e7e7e7;
  border-bottom: solid 1px #e7e7e7;
  margin: 0 -.5rem;
}

.kumat-body-info {
  display: flex;

  &>div {
    flex-grow: 1;
    &>div {
      padding: .5rem;
      &>label, &>div {
        display: block;
        padding: .5rem;
      }
      &>label {
        padding-bottom: 0;
      }
      &>div {
        padding-top: 0;
      }
    }
    
  }
}

.kumat-items-info {
  margin-top: 2rem;
  padding: 1rem;

  &>h1 {
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.kumat-bp-info {
  margin-top: 2rem;
  padding: 1rem;
  border-bottom: solid 1px #e7e7e7;

  &>h1 {
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.kumat-bp-list {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
  &>div {
    display: flex;
    &>div {
      &:first-child {
        /* background-image: url("../../public/no-user-image.gif"); */
        background-size: cover;
        width: 48px;
        height: 48px;
        margin-right: 1rem;
      }
    }
  }
}
